import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import Table from '../../services/table';
import { RootState } from '../store';
// import IndiaMartService from "../../services/indiamart";
import { isEmptyObject } from '../../utilities/Common';
import DBTableManager from '../../managers/DBTableManager';
import { COLUMN_CODE, TABLES } from '../../constants/Constant';
import ReportService from '../../services/reports/Report';

export interface RecordState {
  data: any;
  tickets: any;
  ticketViews: any;
  currentData: any;
  accounts: any;
  peopleEmployeeFields?: any;
  loadingEmployeeFields?:boolean;
}
const initialState: RecordState = {
  data: {},
  tickets: null,
  ticketViews: null,
  currentData: {
    contact: {},
    contactDeal: {},
    contactEmails: {},
    pipelinesStages: {},
    unsubscribeCampaign: {},
    indiamartLeadSyncLogs: {}
  },
  accounts: {},
  peopleEmployeeFields: {},
  loadingEmployeeFields: false
};
/* CRM Core records API goes here */
export const fetchRecordsByTable = createAsyncThunk(
  'record/fetchRecordsByTable',
  async (data: any, thunkAPI) => {
    const tableId = DBTableManager.getTableId(data.tableName);

    if (data.tableName === TABLES.ACTIVITY) {
      data.params = {
        pageNo: 1,
        pageSize: 20,
        fetchAllRef: true,
        ...data.params
      };
    }

    const response = await Table.getRecordsByPage(
      data.params,
      data.payload,
      tableId
    );
    return response;
  }
);
export const getRecordsByTable = createAsyncThunk(
  'record/getRecordsByTable',
  async (data: any, thunkAPI) => {
    const response: any = await thunkAPI.getState();
    return response.records.data[data.tableName];
  }
);

export const getPeopleEmployeeFields = createAsyncThunk(
  'getPeopleEmployeeFields',
  async () => {
    try {
      const response = await ReportService.getInstance().getPeopleEmployeeReportFields();
      return response;
    } catch (err) {
      console.log('err getPeopleEmployeeFields', err);
    }
  }
);
export const getPeopleEmployeeFieldsMappingStatusCheck = createAsyncThunk(
  'getPeopleEmployeeFieldsFieldMappingStatusCheck',
  async () => {
    try {
      const response = await ReportService.getInstance().getPeopleEmployeeReportFieldsMappingStatusCheck();
      return response;
    } catch (err) {
      console.log('err getPeopleEmployeeFields', err);
    }
  }
);
/* Custom records other that CRM Core goes here (import_logs, etc...) */

export const recordSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    setRecords: (state: RecordState, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setTableRecords: (state: RecordState, action: PayloadAction<any>) => {
      state.data[action.payload.tableName] = action.payload.data;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecordsByTable.fulfilled, (state, action) => {
      state.data[action.meta.arg.tableName] = action.payload;
    });
    builder.addCase(fetchRecordsByTable.rejected, (state, action) => {
      state.data[action.meta.arg.tableName] = {
        data: [],
        totalCount: 0
      };
    });
    builder.addCase(getPeopleEmployeeFields.fulfilled, (state, action) => {
      state.peopleEmployeeFields = action.payload;
      state.loadingEmployeeFields = false
    });
    builder.addCase(getPeopleEmployeeFields.pending, (state, action) => {
      state.loadingEmployeeFields = true;
    });
  }
});

export const { setRecords, setTableRecords } = recordSlice.actions;
const selfSelector = (state: RootState) => state;
export const recordsByTableNameSelector = (tableName: string) =>
  createSelector(
    selfSelector,
    (state: RootState) => state.records.data[tableName]
  );
export const getUnsubscribeCampaign = (state: any) =>
  state.records.currentData.unsubscribeCampaign;

  export const getPeopleFieldSelector = () =>
  createSelector( selfSelector, (state: RootState) => state.records.peopleEmployeeFields);
export default recordSlice.reducer;
