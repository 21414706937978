import ic_graph_1 from '../assets/icons/ic_graph_1.png';
import ic_graph_2 from '../assets/icons/ic_graph_2.png';
import ic_graph_3 from '../assets/icons/ic_graph_3.png';
import ic_graph_4 from '../assets/icons/ic_graph_4.png';
import { CHART_TYPE, supportedFileTypes } from './Enum';

/* ************** APP CONSTANTS ************** */
export const APP_SCROLL_CONTAINER_ID = 'rootContainer';

export const PAGE_ROUTES = {
  REPORT_DASHBOARD: '/',
  CREATE_REPORT: '/reports/create',
  EDIT_REPORT: '/reports/create/:id',
  REPORT_DETAILS: '/report-details',
  RECENT: '/recent',
  STARRED: '/starred',
  REPORTS: '/reports',
  TEMPLATES: '/templates',
  PROFIT_LOSS: '/profit-and-loss',
  BALANCE_SHEET: '/balance-sheet',
  TRIAL_BALANCE: '/trial-balance',
  PNL_DETAIL: '/profit-and-loss-detail',
  BS_DETAIL: '/balance-sheet-detail',
  TB_DETAIL: '/trial-balance-detail',
  ACTIVITY_BY_CONTACT_REPORT: '/activity-by-contact-report',
  TICKET_BY_CONTACT_REPORT: '/ticket-by-contact-report',
  UNSUBSCRIBE_REPORT: '/unsubscribe-report',
  CONTACT_WITH_DEALS: '/salesByContact',
  ACTIVITY_REPORT: '/activity-report',
  CUSTOM_REPORT: '/custom-report',
  PIPELINE_STAGE_REPORT: '/pipelinr-stage-Report',
  AUTOMATION_REPORT: '/automation-report',
  SETTINGS: '/settings',
  AI_REPORT: '/ai-report/:id',
  CONNECT_SETTINGS: '/settings/connect',
  PEOPLE_AI_REPORT: '/peopleaireport',
  CREATE_AI_REPORT: '/peopleaireport/create',
  EDIT_AI_REPORT: '/peopleaireport/create/:id'
};

export enum PERMISSION_APP_NAME {
  REPORT_BUILDER = 'REPORT_BUILDER',
  CRM = 'CRM_PLUS'
}

export enum APP_NAME {
  ALL = '',
  ERP = 'ERP',
  CRM = 'CRM',
  CRM3 = 'CRM3',
  PEOPLE = 'PEOPLE',
  ONEDRIVE = 'ONEDRIVE',
  DROPBOX = 'DROPBOX'
}

export const VIEWPORT_SIZES = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  LARGE: 'lg'
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 800
};

export const QUERY_PARAM_KEYS = {
  TEMPLATE_GROUP: 'templateGroup',
  TEMPLATE_NAME: 'templateName',
  AUTOMATION_NAME: 'automationName',
  AUTOMATION_ID: 'automationId',
  REPORT: 'report'
};

/* ****************** CHART CONSTANTS ***************** */
export const CHART_WIDTH = 500;
export const CHART_HEIGHT = 280;
export const CHART_MIN_WIDTH = 300;

export const COLORS = [
  'rgb(28, 115, 232)',
  'rgb(80, 180, 200 )',
  'rgb(222, 51, 150 )',
  'rgb(230, 117, 45 )',
  'rgb(140, 170, 84 )',
  'rgb(86, 58, 170 )',
  'rgb(74, 166, 240 )',
  'rgb(218, 80, 123 )',
  'rgb(52, 168, 83)',
  'rgb(204, 65, 37)',
  'rgb(246, 178, 107)',
  'rgb(118, 165, 175)',
  'rgb(146, 196, 125)',
  'rgb(142, 124, 195)',
  'rgb(166, 77, 120)'
];
export const COLORS_ENUM = {
  deskera: 'rgb(255, 198, 74)',
  deskera_secondary: 'rgb(254, 246, 225)',
  primary: 'black',
  secondary: 'white',
  text_primary: '#343a40',
  text_secondary: 'white',
  white: 'white',
  black: 'black',
  gray: 'rgb(160,160,160)',
  dark_gray: 'rgb(60, 60, 60)',
  maroon: 'rgb(174, 47, 2)',
  orange: 'rgb(240, 126, 37)',
  erp: 'rgb(57, 87, 213)',
  erp_secondary: 'rgb(241, 243, 255)',
  crm: 'rgb(26, 175, 93)',
  crm_secondary: 'rgb(230, 247, 238)',
  people: 'rgb(240, 126, 37)',
  people_secondary: 'rgb(254, 247, 242)',
  aio: 'rgb(8, 18, 90)',
  aio_secondary: 'rgb(240, 243, 255)',
  pricing: 'rgb(255, 198, 74)',
  blue: 'rgb(28, 115, 232)',
  charts: {
    bar_vertical: 'rgb(38, 144, 240)',
    bar_horizontal: 'rgb(52, 195, 161)',
    line: {
      green: 'rgb(52, 195, 161)',
      red: 'rgb(240, 100, 111)',
      yellow: 'rgb(245, 200, 60)'
    },
    pie: [
      'rgb(130, 112, 234)',
      'rgb(250, 200, 85)',
      'rgb(225, 105, 130)',
      'rgb(52, 195, 161)',
      'rgb(143, 232, 236)',
      'rgb(38, 144, 240)',
      'rgb(240, 100, 111)',
      'rgb(245, 200, 60)',
      'rgb(160,160,160)'
    ]
  }
};

export const graphTypes = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4
};
export const graphIcons = {
  ONE: ic_graph_1,
  TWO: ic_graph_2,
  THREE: ic_graph_3,
  FOUR: ic_graph_4
};

export const GRAPHS = [
  {
    type: graphTypes.ONE,
    title: 'Job Progress by Status',
    subtitle:
      'Top jobs with the estimated or bid-time vs the actual time spent are shown',
    icon: graphIcons.ONE,
    expanded: false
  },
  {
    type: graphTypes.TWO,
    title: 'Revenue by Employee',
    subtitle: 'Current view shows the summary for a typical YTD revenue',
    icon: graphIcons.TWO,
    expanded: false
  },
  {
    type: graphTypes.THREE,
    title: 'Upcoming Jobs',
    subtitle:
      'Each job is displayed with Job ID, Job Description, Client, Crew overview and status',
    icon: graphIcons.THREE,
    expanded: false
  },
  {
    type: graphTypes.FOUR,
    title: 'Crew Overview',
    subtitle:
      'It shows the crew information such as the Lead, the Crew Size, Crew utilization',
    icon: graphIcons.FOUR,
    expanded: false
  }
];

export const OVERVIEW_CHART_SPECS = {
  rowHeight: 48,
  minCellWidth: 110
};
export const GRAPH_COLUMN_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  USER: 'user',
  LINE_CHART: 'lineChart',
  ACTION: 'action',
  CUSTOM: 'custom' /* with renderer */
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const LIB_TO_REPORT_OP_CONV: any = {
  eq: 'EQUALS',
  neq: 'EQUALS',
  gt: 'GREATER_THAN',
  gte: 'GREATER_THAN_EQUALS',
  lt: 'LESS_THAN',
  c: 'LIKE',
  sw: 'LIKE',
  ew: 'LIKE'
};
export const REPORT_TO_LIB_OP_CONV: any = {
  EQUALS: 'eq',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  LIKE: 'c'
};
export enum INVITE_USER_STATUS {
  SENT = 'SENT',
  JOINED = 'JOINED'
}
export const NO_USER_VIEW_ALERT =
  "Looks like you don't have permission to view organization users, please contact your organization owner to provide Deskera CRM+ product access.";

export const supportedMimeTypes = {
  [supportedFileTypes.csv]: 'text/csv;charset=utf-8;',
  [supportedFileTypes.xlsx]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const MAX_WIDGET_DATA_PAGE_SIZE = 10;
export const appsForPeople = ['All', APP_NAME.PEOPLE]

export const APPS = [
  {
    name: 'All',
    code: APP_NAME.ALL
  },
  {
    name: 'Books',
    code: APP_NAME.ERP
  },
  {
    name: 'CRM',
    code: APP_NAME.CRM3
  },
  {
    name: 'OneDrive',
    code: APP_NAME.ONEDRIVE
  },
  {
    name: 'DropBox',
    code: APP_NAME.DROPBOX
  }
];
export const AI_SUPPORTED_APPS = [{
  name: 'All',
  code: APP_NAME.ALL
},
{
  name: 'PEOPLE',
  code: APP_NAME.PEOPLE
},]
export const DEFAULT_TRIAL_PLAN_DAYS = 15;
export const PLANS = {
  STARTUP: 'startup',
  ESSENTIAL: 'essential',
  PROFESSIONAL: 'professional'
};
export const MODULES = {
  REPORT: 'REPORT'
};
export const MODULE_EVENT = {
  REPORT: {
    REC_CREATE: 'Report create',
    REC_UPDATE: 'Report update',
    REC_DELETE: 'Report delete'
  }
};
export const ACTIVITY_GROUP_BY_CONTACT = `Activities grouped by Contacts`;
export const TICKET_GROUP_BY_CONTACT = `Tickets grouped by Contacts`;
export const US_COUNTRY_CODE = 'US';
export const ACTIVITY_DATE_FORMAT = 'EEE, MMM dd yyyy, HH:mm aa';
export const ACTIVITY_REPORT_NAME = `Overdue Activities`;
export const REGEX = {
  WHATSAPP: /^((\D+)?0+)|\D/g,
  TEXT_TO_LINK:
    /(\b(((https?|ftp|file):)\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
};
export const GOOGLE_MAP_URL = 'https://www.google.com/maps?q=';
export const CHAR_CODES = {
  ENTER: 13,
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  TOP_ARROW: 38,
  DOWN_ARROW: 40
};
export const CHART_INTERVALS = [
  {
    name: 'Last Week',
    interval: 'weekly'
  },
  {
    name: 'Last Month',
    interval: 'monthly'
  },
  {
    name: 'Last Year',
    interval: 'yearly'
  }
];
export const TABLES = {
  CONTACT: 'contact',
  DEAL: 'deal',
  SEGMENT: 'segment',
  CAMPAIGN: 'campaign',
  FORM: 'form',
  LANDING_PAGE: 'landing_page',
  PIPELINE: 'pipeline',
  STAGE: 'stage',
  ACTIVITY: 'activity',
  ACCOUNT: 'account',
  AUTOMATION: 'automation',
  FUNNEL: 'funnel',
  ORDER: 'order',
  PRODUCT: 'product',
  IMPORT_LOG: `import_log`,
  CHAT_MESSAGE: 'chat_message',
  AUTOMATION_LOG: 'automation_log',
  BOOKS_QUOTE: 'books_quote',
  AUDIT_LOG: 'audit_log',
  TICKET: 'ticket',
  PERMISSION: 'permission',
  OVERDUE_ACTIVITY: 'overdueActivity',
  SHOP_LEAD: 'shop_lead',
  BOOKS_INVOICE: 'books_invoice',
  DRAFT: 'draft',
  TEMPLATE: 'template',
  LEAD_SCORE: 'lead_score'
};
export const COLUMN_CODE = {
  CONTACT: {
    NAME: `name`,
    EMAIL: `email`,
    TYPE: `type`,
    PHONE: `phone`,
    ORGANISATION: `organization`,
    ADDRESS: `address`,
    SEGMENT: `segment`,
    STATUS: `status`,
    LABEL: `label`,
    PROFILE_PIC: `profile_pic`,
    ATTACHMENT: `attachment`,
    SOURCE: `source`,
    BOOKS_CONTACT_ID: `books_contact_id`,
    SOURCE_ID: `source_id`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    ACCOUNT: 'account_id',
    CREATED_AT: `created_at`,
    NOTE: `note`,
    LEAD_SCORE: `lead_score`
  },
  CAMPAIGN: {
    NAME: `name`,
    TYPE: `type`,
    PUBLISHED_ON: `published_on`,
    DELIVERED: `delivered`,
    OPEN: `open`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    PUBLISHED: `published`,
    CAMPAIGN_TYPE: `campaign_type`,
    DIRECT_LINK: `direct_link`,
    SEGMENT_ID: `segment_id`,
    EMAIL_SUBJECT: `email_subject`,
    EMAIL_FROM: `email_from`,
    EMAIL_PRE_HEADER: `email_preheader`,
    HASH: `hash`,
    SEND_NOW: `send_now`,
    SMTP_SETUP_ID: `smtp_setup_id`
  },
  FORMS: {
    NAME: `name`,
    EMBED_URL: `embed_url`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    SEGMENT_ID: `segment_id`,
    PUBLISHED: `published`,
    HASH: `hash`,
    SLUG: `slug`,
    CUSTOM_DOMAIN: 'custom_domain'
  },
  LANDING_PAGE: {
    NAME: `name`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    PUBLISHED: `published`,
    SEGMENT_ID: `segment_id`,
    HASH: `hash`,
    SLUG: `slug`,
    CUSTOM_DOMAIN: `custom_domain`
  },
  PIPELINE: {
    NAME: `name`,
    STAGE_ID_S: `stage_id_s`,
    IS_DEFAULT: `is_default`
  },
  STAGES: {
    NAME: `name`,
    ORDER: `order`,
    WON_STAGE: `won_stage`,
    LOST_STAGE: `lost_stage`,
    HELP_TEXT: `help_text`
  },
  DEAL: {
    NAME: `name`,
    CLOSING_DATE: `closing_date`,
    STAGE_ID: `stage_id`,
    PIPELINE_ID: `pipeline_id`,
    AMOUNT: `amount`,
    CONTACT_ID: `contact_id`,
    DEAL_DATE: `deal_date`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    VISIBILITY: `visibility`,
    ORGANIZATION_ID: `organization_id`,
    STATUS: `status`,
    REASON: `reason`,
    SOURCE: 'source',
    SOURCE_ID: 'source_id',
    NOTE: `note`,
    ATTACHMENT: `attachment`,
    CREATED_AT: `created_at`
  },
  ACTIVITY: {
    NAME: `name`,
    ACTIVTY_TYPE: `activity_type`,
    START_DATE: `start_date`,
    END_DATE: `end_date`,
    NOTES: `notes`,
    ASSIGNEE_IDS: `assignee_ids`,
    CONTACT_IDS: `contact_ids`,
    ORGANIZATION_IDS: `organization_ids`,
    LINKED_TO: `linked_to`,
    STATUS: `status`,
    SEND_INVITATION: `send_invitation`,
    SEND_CONTACTS_INVITATION: `send_contacts_invitation`,
    OWNER_ID: `owner_id`,
    TIMEZONE: `timezone`
  },
  FUNNEL: {
    NAME: `name`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    LEADS: `leads`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    PUBLISHED: `published`,
    SEGMENT_ID: `segment_id`,
    HASH: `hash`,
    CUSTOM_DOMAIN: 'custom_domain'
  },
  AUTOMATION: {
    NAME: `name`,
    CREATED_ON: `created_on`,
    SCHEDULED_ON: `scheduled_on`,
    LAST_RUN_DATE: `last_run_date`,
    JSON_DATA: `json_data`,
    STATUS: `status`,
    CONNECTION_ID: `workflow_connection_id`
  },
  PRODUCT: {
    NAME: `name`,
    DESCRIPTION: `description`,
    PRICE: `price`
  },
  ORDER: {
    PRODUCT_ID: 'products_ids',
    TRANSACTION_ID: 'txn_id',
    CUSTOMER: 'customer',
    NAME: 'name',
    EMAIL: 'email',
    PHONE: 'phone',
    STREET_ADDRESS: 'street_address',
    CITY: 'city',
    STATE: 'state',
    COUNTRY: 'country',
    LANDMARK: 'landmark',
    'POSTAL CODE': 'postal_code'
  },
  IMPORT_LOG: {
    MODULE: 'module',
    RECORDS: 'totalRecords',
    FILE_NAME: 'fileName',
    FILE_TYPE: 'fileType',
    IMPORTED_BY: 'importedBy',
    IMPORTED_ON: 'createdAt'
  },
  AUTOMATION_LOGS: {
    AUTOMATION: 'automationName',
    TRIGGER_TYPE: 'triggerNodeType',
    ACTION_TYPE: 'executedNodeType',
    LOG: 'log',
    EXECUTED_AT: 'executedAt'
  },
  BOOKS_QUOTE: {
    ID: 'id',
    NUMBER: 'documentSequenceCode',
    CONTACT: 'contact',
    CONTACT_CODE: 'contactCode',
    QUOTE_DATE: 'documentDate',
    DUE_DATE: 'validTillDate',
    TOTAL_AMOUNT: 'totalAmount',
    FULFILLMENT: 'fulfillmentStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    STATUS: 'status'
  },
  ACCOUNT: {
    NAME: `name`,
    EMAIL: `email`,
    PHONE: `phone`,
    ADDRESS: `address`,
    WEBSITE_URL: `website_url`,
    NOTE: `note`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    BOOKS_CONTACT_ID: `books_contact_id`
  },
  AUDIT_LOG: {
    ACTIONS: 'actionName',
    ACTION_BY: 'actionBy',
    ACTION_DATE: 'actionDate',
    LOG: 'log',
    OLD_VALUES: 'old_values'
  },
  BOOKS_INVOICE: {
    NUMBER: 'documentSequenceCode',
    CONTACT: 'contact',
    INVOICE_DATE: 'salesInvoiceDate',
    DUE_DATE: 'salesInvoiceDueDate',
    TOTAL_AMOUNT: 'totalAmount',
    DUE_AMOUNT: 'dueAmount',
    FULFILLMENT: 'fulfillmentStatus',
    PAYMENT: 'paymentStatus',
    APPROVAL_STATUS: 'approvalStatus',
    CREATED_BY: 'createdBy',
    DOCUMENT_TYPE: 'recurring',
    E_INVOICING: 'invoiceNow',
    STATUS: 'status'
  },
  DRAFTS: {
    ID: 'id',
    NAME: 'name',
    TYPE: 'type',
    PAYLOAD: 'payload',
    IS_MAXIMIZED: 'is_maximized',
    IS_CENTER_ALIGNED: 'is_center_align',
    IS_SAVED: 'is_saved'
  },
  TEMPLATE: {
    NAME: 'name',
    HTML_DATA: 'html_data',
    TAGS: 'tags'
  }
};
/* ****************** ACCOUNTING CONSTANTS ***************** */
export const PNL: { [key: string]: string } = {
  expenses: 'totalExpenses',
  income: 'totalIncome',
  otherExpenses: 'totalOtherExpenses',
  otherIncome: 'totalOtherIncome',
  costOfGoodsSold: 'totalCostOfGoodsSold',
  revenue: 'totalRevenue',
  cogs: 'totalCogs',
  operatingExpenses: 'totalOperatingExpenses'
};

export const PNL2: { [key: string]: string } = {
  totalCostOfGoodsSold: 'costOfGoodsSold',
  totalExpenses: 'expenses',
  totalIncome: 'income',
  totalOtherExpenses: 'otherExpenses',
  totalOtherIncome: 'otherIncome',
  totalRevenue: 'revenue',
  totalCogs: 'cogs',
  totalOperatingExpenses: 'operatingExpenses'
};

export const PNL3: { [key: string]: boolean } = {
  grossProfit: true,
  incomeTaxExpenses: true,
  netProfitOrLoss: true,
  operatingProfit: true,
  profitBeforeTax: true
};

export const BALANCE_SHEET_LABEL: any = {
  assets: 'assets',
  liabilities: 'liabilities'
};
export const BALANCE_SHEET_STYLE: any = {
  liabilities: 'liabilities'
};

export const BALANCE_SHEET1: any = {
  totalLiabilities: 'totalLiabilities',
  totalAsset: 'totalAsset'
};

export const BALANCE_SHEET2: any = {
  equity: 'equity',
  bank: 'bank',
  cash: 'cash',
  assets: 'assets',
  liabilities: 'liabilities',
  currentAsset: 'currentAsset',
  nonCurrentAsset: 'nonCurrentAsset',
  otherCurrentAssets: 'otherCurrentAssets',
  totalAsset: 'totalAsset',
  currentLiabilities: 'currentLiabilities',
  nonCurrentLiabilities: 'nonCurrentLiabilities',
  otherCurrentLiabilities: 'otherCurrentLiabilities',
  longTermLiabilities: 'longTermLiabilities',
  diffOpeningBalanceAsset: 'diffOpeningBalance',
  diffOpeningBalanceLiability: 'diffOpeningBalance',
  totalLiabilities: 'totalLiabilities',
  longTermAssets: 'longTermAssets',
  accountsReceivable: 'accountsReceivable',
  fixedAssets: 'fixedAssets',
  otherAssets: 'otherAsset',
  ppe: 'ppe',
  intangibles: 'intangibles', //
  otherLongTermAsset: 'otherLongTermAsset',
  accountsPayable: 'accountsPayable'
};

export const BALANCE_SHEET3: any = {
  currentAsset: true,
  currentLiabilities: true,
  equity: true
};

export const BALANCE_SHEET_TOTAL: { [key: string]: string } = {
  bank: 'totalBank',
  cash: 'totalCash',
  currentAsset: 'totalCurrentAsset',
  nonCurrentAsset: 'totalNonCurrentAsset',
  otherCurrentAssets: 'totalOtherCurrentAssets',
  equity: 'totalEquity',
  currentLiabilities: 'totalCurrentLiabilities',
  nonCurrentLiabilities: 'totalNonCurrentLiabilities',
  otherCurrentLiabilities: 'totalOtherCurrentLiabilities',
  longTermAssets: 'totalLongTermAssets',
  fixedAssets: 'totalFixedAssets', //
  accountsReceivable: 'totalAccountsReceivable',
  otherAssets: 'totalOtherAssets', //
  ppe: 'totalPpe', //
  intangibles: 'totalIntangibles', //
  otherLongTermAsset: 'totalOtherLongTermAsset', //
  accountsPayable: 'totalAccountsPayable',
  longTermLiabilities: 'totalLongTermLiabilities'
};

export const TRIAL_BALANCE1: any = {
  totalDebitAmount: true,
  totalCreditAmount: true,
  diffOpeningBalance: true
};
export const AI_REPORT_TABLE_CONSTANT = {
  PEOPLE_EMPLOYEE_DETAILS: 'people_employee_details_report',
  PEOPLE_TOTAL_EMPLOYEE_TYPE: 'total_employee_type',
  PEOPLE_EMPLOYEE_TERMINATION_STATUS: 'total_employee_termination_status',
  PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT: 'total_employee_dept',
  PEOPLE_EMPLOYEE_PROMOTION_STATUS: 'total_employee_promotion_status',
  PEOPLE_EMPLOYEE_STATUS: 'total_employee_status',
  PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:'total_voluntary_involuntary_termination'

}
export const AI_CHART_TYPE_SUPPORT = [CHART_TYPE.TABLE];
export const AI_TABLE_SUPPORTED = [
  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_TYPE,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_TERMINATION_STATUS,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_PROMOTION_STATUS,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_STATUS,
  AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION
];
export const STATUS = {
  ACTIVE: 'active'
}
export const ALLOW_PEOPLE_REPORT_FILTER_COLUMN = ['tenant_id','employmentStatus']

export const HIDE_EXTRA_COLUMNS = {
  [AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS]: [ 'dateOfTermination', 'terminationReason', 'terminationType', 'noticePeriod', 'terminationDate', 'exitInterviewComment', 'clearanceFromEachDept', 'exitInterviewComment', 'employeePersonalEmail']
}
export const TABLES_TO_REPLACE_DROPDOWN_FIELD_CONFIG = {
  [AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION]: ['terminationReason', 'terminationType'],
  // [AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT]: ['departmentCostCenter','department']
};

export const MASK_OPTION = {
  id: '*****',
  name: '*****'
};
