import { AI_REPORT_TABLE_CONSTANT, TABLES } from '../constants/Constant';
import { FILTER_LOGICAL_OPERATORS } from '../constants/Enum';
import {
  IColumn,
  IColumnDataSource,
  IFilterCondition,
  ITableFilter
} from '../services/reports/Table';
import { fetchTableByName, getTableByName } from '../store/slices/tableSlice';
import { store } from '../store/store';
import { INPUT_TYPE } from 'deskera-ui-library';
import TableDataParser from '../helper/TableDataParser';
import { deepCloneObject, isEmptyObject, modifyPeopleColumn, modifyPeopleOtherReportColumn } from '../utilities/Common';
import { getPeopleFieldSelector } from '../store/slices/recordSlice';
import { getMultiTenantAccess, selectPeopleUsers } from '../store/slices/tenantSlice';
export const TABLE_DISPLAY_NAME = {
  [TABLES.CONTACT]: `Contacts`,
  [TABLES.SEGMENT]: `Contacts`,
  [TABLES.DEAL]: `Deals`,
  [TABLES.CAMPAIGN]: `Campaigns`,
  [TABLES.FORM]: `Forms`,
  [TABLES.LANDING_PAGE]: `Landing Pages`,
  [TABLES.AUTOMATION]: `Automations`,
  [TABLES.FUNNEL]: `Funnels`,
  [TABLES.ORDER]: `Orders`,
  [TABLES.IMPORT_LOG]: `Import Logs`,
  [TABLES.ACCOUNT]: 'Accounts',
  [TABLES.ACTIVITY]: `Activities`,
  [TABLES.AUTOMATION_LOG]: `Automation Logs`,
  [TABLES.BOOKS_QUOTE]: `Quotes`,
  [TABLES.AUDIT_LOG]: `Audit Logs`,
  [TABLES.TICKET]: `Ticket`,
  [TABLES.SHOP_LEAD]: `Shop leads`
};
export default class DBTableManager {
  static tableMap = new Map();
  static tableIdNameMap = new Map();
  static reportTablesData = null;
  static getAllReportsTableData() {
    return DBTableManager.reportTablesData;
  }
  static setAllReportsTableData(data: any) {
    DBTableManager.reportTablesData = data;
  }
  static performInitialColumnUpdates(tableName?: string) {
    TableDataParser.putDataInOwnersColumn(store.getState().tenant?.users);

    switch (tableName) {
      case TABLES.CONTACT:
        TableDataParser.checkAndReplaceSegment();
        break;
      case TABLES.ACTIVITY:
        TableDataParser.checkAndReplaceActivityData(
          store.getState().records?.data[TABLES.ACTIVITY]?.data,
          store.getState().tenant?.users
        );
        break;
      default:
        TableDataParser.checkAndReplaceSegment();
        TableDataParser.checkAndReplaceActivityData(
          store.getState().records?.data[TABLES.ACTIVITY]?.data,
          store.getState().tenant?.users
        );
    }
  }
  static setTableMap(parsedTableData: any, tableName: string) {
    this.tableMap.set(tableName, parsedTableData);

    if (tableName === TABLES.CONTACT) {
      this.tableMap.set(TABLES.SEGMENT, parsedTableData);
    } else if (tableName === TABLES.SEGMENT) {
      this.tableMap.set(TABLES.CONTACT, parsedTableData);
    }
  }
  static populateViewMap(data: any) {
    data.forEach((item: any) => {
      const parsedTableData = TableDataParser.parseTableData(item);
      this.tableIdNameMap.set(item._id, item.name);
      this.setTableMap(parsedTableData, item.name);
    });

    this.performInitialColumnUpdates('');
  }
  static setTableColumns(columnData: any, tableName: string) {
    const parsedTableData = {
      ...this.tableMap.get(tableName),
      ...TableDataParser.parseTableData(columnData)
    };
    this.setTableMap(parsedTableData, tableName);
    this.performInitialColumnUpdates(tableName);
  }
  static updateColumn(columnCode: any, tableName: any, columnData: any) {
    let columnsIndex = this.tableMap
      .get(tableName)
      .columns.findIndex((column: any) => column.columnCode === columnCode);
    let columns = this.tableMap.get(tableName).columns;
    columns.splice(columnsIndex, 1, columnData);

    const updatedTableData = { ...this.tableMap.get(tableName), columns };
    this.setTableMap(updatedTableData, tableName);
  }
  static getTableId(tableName: string): string {
    return this.tableMap.get(tableName)?.id;
  }
  static getTableName(tableName: string): string {
    return this.tableMap.get(tableName).name;
  }
  static getTableNameFromId(tableId: string): string {
    return this.tableIdNameMap.get(tableId);
  }
  static getTableColumns(tableName: string): IColumn[] {
    return this.tableMap.get(tableName)?.columns;
  }
  static getColumnCodeKeyMap(tableName: string): Map<string, string> {
    return this.tableMap
      .get(tableName)
      .columns.reduce((acc: any, column: any) => {
        acc[column.columnCode] = column.key;
        return acc;
      }, {});
  }
  static getTableVisibleColumns(tableName: string): IColumn[] {
    return this.getTableColumns(tableName)?.filter((column) => !column.hidden);
  }
  static getTableFilteredColumns(
    tableName: string,
    condition?: any,
    filterEditable?: Boolean
  ): IColumn[] {
    return this.getTableColumns(tableName)?.filter((column) => {
      const isIncluded = condition(column);
      const isEditable = column.uiVisible && column.editable;

      return filterEditable ? isIncluded && isEditable : isIncluded;
    });
  }
  static getColumnId(tableName: string, columnCode: string): string {
    let column: any = this.getColumn(tableName, columnCode);
    return column ? column.id : undefined;
  }
  static getColumn(tableName: string, columnCode: string): any {
    return this.getTableColumns(tableName)?.find(
      (column) => column.columnCode === columnCode
    );
  }
  static getFilterObject(
    data: any,
    logicalOperator?: FILTER_LOGICAL_OPERATORS
  ): ITableFilter {
    let filterObject: ITableFilter = {
      logicalOperator: logicalOperator ?? FILTER_LOGICAL_OPERATORS.AND,
      conditions: []
    };
    data.forEach((item: any) =>
      filterObject.conditions.push(this.getFilterSubCondition(item))
    );
    return filterObject;
  }
  static getFilterSubCondition(item: any): IFilterCondition {
    return {
      colId: item.key,
      value: item.value,
      opr: item.condition,
      isExternal: item.isExternal ?? false
    };
  }
  static isSelectField(column: any) {
    return (
      column.type === INPUT_TYPE.SELECT ||
      column.type === INPUT_TYPE.MULTI_SELECT ||
      column.type === 'ref'
    );
  }

  static refreshTable = (tableName: any) => {
    store.dispatch(fetchTableByName({ tableName })).then((res) => {
      if (!isEmptyObject(res?.payload)) {
        DBTableManager.setTableColumns(deepCloneObject(res.payload), tableName);
      }
    });
  };
  static getOptionsFromDataSource(dataSource: IColumnDataSource) {
    const targetTableName = dataSource?.target
      ? this.getTableNameFromId(dataSource.target)
      : ``;
    const sourceColumnKey = (dataSource.valueKey || '').replace('cells.', '');

    if (!this.tableMap.has(targetTableName) || !sourceColumnKey) return;

    const sourceColumnData = this.getTableColumns(targetTableName)?.find(
      (column: IColumn) => column.id === sourceColumnKey
    );

    return sourceColumnData?.options || [];
  }

  static getTableDataByName(tableName: string, isMultiTenantData: boolean) {
    const hasMultiTenantAccess = getMultiTenantAccess()(store.getState())
    let table = getTableByName(tableName)(store.getState())
    const peopleTable = getPeopleFieldSelector()(store.getState())
    const peopleUsers = selectPeopleUsers()(store.getState())
    if(tableName === AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_DETAILS) {
      
      if(peopleTable?.fieldConfigs) {
        table = {...table};
        const tableModify = {
          columnCode: 'reportingManagerId',
          options: peopleUsers,
          orgKey:'tenant_id',
          orgList: hasMultiTenantAccess?.orgs || []
        }
        table.fields = modifyPeopleColumn(peopleTable.fieldConfigs, tableModify, tableName, true, isMultiTenantData)
      }
    }
    const tableParam = {
      orgKey:'tenant_id',
      orgList: hasMultiTenantAccess?.orgs || [],
      options: peopleUsers || [],
      tableName: tableName,
      fieldConfigs: peopleTable?.fieldConfigs
    }
    switch(tableName) {
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_DEPARTMENT:
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_PROMOTION_STATUS:
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_STATUS:
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_EMPLOYEE_TERMINATION_STATUS:
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_EMPLOYEE_TYPE:
      case AI_REPORT_TABLE_CONSTANT.PEOPLE_TOTAL_VOLUNTARY_INVOLUNTARY_TERMINATION:
        table = {...table};
        table.fields = modifyPeopleOtherReportColumn(table.fields,tableParam);
        break;
      default:
        break;
    }
    return table;
  }

  static getColumnDisplayName(columnName: string, tableName: string, isMultiTenantData: boolean) {
    let tableData = DBTableManager.getTableDataByName(tableName, isMultiTenantData);
    let columnData = tableData?.fields?.find((obj) => obj.name === columnName);
    return columnData?.display || '';
  }

  static getColumnDataType(columnName: string, tableName: string, isMultiTenantData: boolean) {
    let tableData = DBTableManager.getTableDataByName(tableName, isMultiTenantData);
    let columnData = tableData?.fields?.find((obj) => obj.name === columnName);
    return columnData?.data_type.toUpperCase() || 'STRING';
  }

  static getColumnData(columnName: string, tableName: string, isMultiTenantData: boolean) {
    let tableData = DBTableManager.getTableDataByName(tableName, isMultiTenantData);
    let columnData = tableData?.fields?.find((obj) => obj.name === columnName);
    return columnData;
  }
}
