import React, { Suspense, useEffect, useState } from 'react';
import '../styles/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { DKSpinner, showLoader, removeLoader } from 'deskera-ui-library';
import ErrorBoundary from './ErrorBoundary';

import {
  APP_NAME,
  APP_SCROLL_CONTAINER_ID,
  PAGE_ROUTES,
  PERMISSION_APP_NAME
} from '../constants/Constant';
import { isEmptyObject, isMacintosh } from '../utilities/Common';
import AuthService from '../services/user/Auth';
import { useAppDispatch } from '../store/hooks';
import {
  fetchCRMCurrencySettings,
  fetchEmployeeListWithoutFilter,
  fetchMultiTenantAccess,
  fetchTenantInfo,
  fetchTenantPermissions,
  fetchTenants,
  setLoggedInUserInfo,
  setLoggedInUserPlanInfo,
  setUserInfo
} from '../store/slices/tenantSlice';
import TopMenu from './menu/TopMenu';
import LeftMenu from './menu/LeftMenu';
import {
  DATE_FN_DATE_FORMATS,
  DATE_FORMATS,
  DATE_FORMATS_GRID,
  LOCAL_STORAGE_KEYS
} from '../constants/Enum';
import { setItemInLocalStorage } from '../utilities/LocalStorage';
import CreateReport from '../pages/CreateReport';
import ReportList from './dashboard/ReportList';
import Templates from './dashboard/Templates';
import Recent from './dashboard/Recent';
import AuthManager, {
  parseAuthToken,
  parseJWTToken
} from '../managers/AuthManager';
import {
  fetchReportTablesByAppName,
  fetchTables
} from '../store/slices/tableSlice';
import Starred from './dashboard/Starred';
import Auth from '../services/user/Auth';
import { lazyWithPreload } from '../utilities/LazyPreload';
import ProfitAndLoss from '../pages/ProfitAndLossReport';
import BalanceSheet from '../pages/BalanceSheetReport';
import TrialBalance from '../pages/TrialBalanceReport';
import ProfitAndLossDetails from '../pages/PNLDetailsReport';
import BalanceSheetDetails from '../pages/BSDetailReport';
import TrialBalanceDetail from '../pages/TBDetails';
import Settings from '../pages/Settings';
import ConnectAccounts from '../pages/ConnectAccounts';

import { fetchAppIntegrationMapping } from '../store/slices/appIntegrationSlice';
import initializeCopilot from 'deskera-copilot';
import CopilotReport from '../pages/CopilotReport';
import { CopilotManager } from '../managers/CopilotManager';
import RouteManager from '../managers/RouteManager';
import PeopleReportList from './dashboard/PeopleReportList';
import CreateAIReport from '../pages/CreateAiReport';
import { getPeopleEmployeeFields, getPeopleEmployeeFieldsMappingStatusCheck } from '../store/slices/recordSlice';

const ActivityByContactReport = lazyWithPreload(
  () => import('../pages/ActivityByContactReport')
);
const TicketByContactReport = lazyWithPreload(
  () => import('../pages/TicketByContactReport')
);
const AutomationReport = lazyWithPreload(
  () => import('../pages/AutomationReport')
);
const ActivityReport = lazyWithPreload(() => import('../pages/ActivityReport'));

const App = (props: any) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  //app.js
  useEffect(() => {
    showLoader('Starting report builder...');
    AuthService.getInstance()
      .checkIfUserLoggedIn(false, onLoginSuccess)
      .catch((err) => AuthManager.gotoLoginPage())
      .finally(removeLoader);
    dispatch(fetchTables());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeCopilot({
      env: process.env.REACT_APP_CO_PILOT_ENV,
      responseCallBack
    });
  }, []);

  function onLoginSuccess(response: any) {
    setLoading(false);

    dispatch(fetchTenantInfo()).then((res) => {
      const data: any = res?.payload;
      if (!data) return;

      let dateFormat: DATE_FORMATS =
        data[LOCAL_STORAGE_KEYS.DATE_FORMATE] || 'DD-MM-YYYY';
      setItemInLocalStorage(
        LOCAL_STORAGE_KEYS.DATE_FORMATE,
        DATE_FN_DATE_FORMATS[dateFormat]
      );
      setItemInLocalStorage(
        LOCAL_STORAGE_KEYS.GRID_DATE_FORMAT,
        DATE_FORMATS_GRID[dateFormat] || DATE_FORMATS_GRID['DD-MM-YYYY']
      );
      setItemInLocalStorage(
        LOCAL_STORAGE_KEYS.DECIMAL_SCALE,
        data[LOCAL_STORAGE_KEYS.DECIMAL_SCALE]
      );
      setItemInLocalStorage(
        LOCAL_STORAGE_KEYS.BOOK_BEGINNING_DATE,
        data[LOCAL_STORAGE_KEYS.BOOK_BEGINNING_DATE]
      );
    });
    const userData = parseAuthToken(parseJWTToken(response.accessToken));
    dispatch(setLoggedInUserInfo(userData));
    if (userData?.id) {
      dispatch(fetchMultiTenantAccess(userData?.id));
      dispatch(fetchEmployeeListWithoutFilter());
    }
    Promise.all([
      Auth.getInstance()
        .checkUserSubscription()
        .then((res) => {
          if (res) {
            dispatch(setLoggedInUserPlanInfo(res));
          }
        })
        .catch((err) => {}),
      dispatch(fetchTenantPermissions())
    ]).then((res: any) => {
      try {
        const permittedApps = res[1].payload.apps;
        const isReportBuilderPermitted = permittedApps.some(
          (app: any) => app.appName === PERMISSION_APP_NAME.REPORT_BUILDER
        );

        if (isReportBuilderPermitted) return Promise.resolve('');

        AuthManager.getProductPermissionAlert();
      } catch (err) {}
    });

    dispatch(fetchCRMCurrencySettings());
    dispatch(fetchTenants());
    AuthService.fetchUsersInfo({ query: `id=${userData?.id}` }).then(
      (res: any) => {
        if (res?.content?.length > 0) {
          dispatch(setUserInfo(res.content[0]));
        }
      }
    );
    const dataToSend = {
      appName: `${APP_NAME.CRM3},${APP_NAME.ERP},${APP_NAME.PEOPLE}`
    };
    dispatch(getPeopleEmployeeFields())
    dispatch(fetchReportTablesByAppName(dataToSend));
    dispatch(fetchAppIntegrationMapping());
    dispatch(getPeopleEmployeeFieldsMappingStatusCheck())
  }

  const responseCallBack = (data: any) => {
    if (!isEmptyObject(data?.detail?.content?.attachments)) {
      CopilotManager.setData(data?.detail);
      RouteManager.navigateToPage(
        PAGE_ROUTES.AI_REPORT.replace(':id', data?.detail?._id)
      );
    }
  };
  return (
    <Router>
      <div className="main-holder bg-white row align-items-start app-font">
        {/* Left Menu */}
        <LeftMenu />
        <div className="parent-width parent-height column flex-1">
          <TopMenu />
          <div
            id={APP_SCROLL_CONTAINER_ID}
            className={
              'main-holder-padding parent-width column position-relative ' +
              (isMacintosh() ? '' : ' show-scroll-bar')
            }
            style={{
              overflowY: 'auto',
              flexGrow: 1,
              paddingBottom: 60,
              paddingTop: 0
            }}
          >
            {loading ? null : (
              <ErrorBoundary>
                <Suspense
                  fallback={
                    <div className="row flex-1 justify-content-center">
                      <DKSpinner />
                    </div>
                  }
                >
                  <Switch>
                    <Route
                      path={PAGE_ROUTES.EDIT_REPORT}
                      component={CreateReport}
                    />
                    <Route
                      path={PAGE_ROUTES.CREATE_REPORT}
                      component={CreateReport}
                    />
                    <Route
                      path={PAGE_ROUTES.EDIT_AI_REPORT}
                      component={CreateAIReport}
                    />
                    <Route
                      path={PAGE_ROUTES.CREATE_AI_REPORT}
                      component={CreateAIReport}
                    />
                    <Route path={PAGE_ROUTES.TEMPLATES} component={Templates} />

                    <Route path={PAGE_ROUTES.RECENT} component={Recent} />
                    <Route path={PAGE_ROUTES.STARRED} component={Starred} />
                    <Route
                      exact
                      path={PAGE_ROUTES.ACTIVITY_REPORT}
                      component={ActivityReport}
                    />
                    <Route
                      exact
                      path={PAGE_ROUTES.AUTOMATION_REPORT}
                      component={AutomationReport}
                    />
                    <Route
                      exact
                      path={PAGE_ROUTES.ACTIVITY_BY_CONTACT_REPORT}
                      component={ActivityByContactReport}
                    />
                    <Route
                      exact
                      path={PAGE_ROUTES.TICKET_BY_CONTACT_REPORT}
                      component={TicketByContactReport}
                    />
                    <Route path={PAGE_ROUTES.REPORTS} component={ReportList} />
                    <Route
                      path={PAGE_ROUTES.PEOPLE_AI_REPORT}
                      component={PeopleReportList}
                    />

                    <Route
                      path={PAGE_ROUTES.PROFIT_LOSS}
                      component={ProfitAndLoss}
                    />
                    <Route
                      path={PAGE_ROUTES.PNL_DETAIL}
                      component={ProfitAndLossDetails}
                    />
                    <Route
                      path={PAGE_ROUTES.BALANCE_SHEET}
                      component={BalanceSheet}
                    />
                    <Route
                      path={PAGE_ROUTES.BS_DETAIL}
                      component={BalanceSheetDetails}
                    />
                    <Route
                      path={PAGE_ROUTES.TRIAL_BALANCE}
                      component={TrialBalance}
                    />
                    <Route
                      path={PAGE_ROUTES.TB_DETAIL}
                      component={TrialBalanceDetail}
                    />

                    {/* ***************** Settings ****************** */}
                    <Route
                      path={PAGE_ROUTES.CONNECT_SETTINGS}
                      component={ConnectAccounts}
                    />
                    <Route path={PAGE_ROUTES.SETTINGS} component={Settings} />
                    <Route
                      path={PAGE_ROUTES.AI_REPORT}
                      component={CopilotReport}
                    />

                    <Redirect path="/" to={PAGE_ROUTES.REPORTS} />
                  </Switch>
                </Suspense>
              </ErrorBoundary>
            )}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
